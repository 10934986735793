import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getConfig } from '@edx/frontend-platform';
import { sendPageEvent, sendTrackEvent } from '@edx/frontend-platform/analytics';
import { useIntl } from '@edx/frontend-platform/i18n';
import {
  Form,
  Hyperlink,
  Tab,
  Tabs,
} from '@edx/paragon';
import {
  AuthnLayout,
  Icon,
  LayoutWrapper,
  LoadingButton,
  TextField,
  Typography,
} from 'frontend-design-system';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import { DEFAULT_STATE, LOGIN_PAGE, VALID_EMAIL_REGEX } from '../data/constants';
import { updatePathWithQueryParams, windowScrollTo } from '../data/utils';
import { forgotPassword, setForgotPasswordFormData } from './data/actions';
import { forgotPasswordResultSelector } from './data/selectors';
import ForgotPasswordAlert from './ForgotPasswordAlert';
import messages from './messages';

const ForgotPasswordPage = (props) => {
  const platformName = getConfig().SITE_NAME;
  const emailRegex = new RegExp(VALID_EMAIL_REGEX, 'i');
  const {
    status, submitState, emailValidationError,
  } = props;

  const { formatMessage } = useIntl();
  const [email, setEmail] = useState(props.email);
  const [bannerEmail, setBannerEmail] = useState('');
  const [formErrors, setFormErrors] = useState('');
  const [validationError, setValidationError] = useState(emailValidationError);
  const [key, setKey] = useState('');

  useEffect(() => {
    sendPageEvent('login_and_registration', 'reset');
    sendTrackEvent('edx.bi.password_reset_form.viewed', { category: 'user-engagement' });
  }, []);

  useEffect(() => {
    setValidationError(emailValidationError);
  }, [emailValidationError]);

  const getValidationMessage = (value) => {
    let error = '';

    if (value === '') {
      error = formatMessage(messages['forgot.password.empty.email.field.error']);
    } else if (!emailRegex.test(value)) {
      error = formatMessage(messages['forgot.password.page.invalid.email.message']);
    }

    return error;
  };

  const handleBlur = () => {
    props.setForgotPasswordFormData({ email, emailValidationError: getValidationMessage(email) });
  };

  const handleFocus = () => props.setForgotPasswordFormData({ emailValidationError: '' });

  const handleSubmit = (e) => {
    e.preventDefault();
    setBannerEmail(email);

    const error = getValidationMessage(email);
    if (error) {
      setFormErrors(error);
      props.setForgotPasswordFormData({ email, emailValidationError: error });
      windowScrollTo({ left: 0, top: 0, behavior: 'smooth' });
    } else {
      props.forgotPassword(email);
    }
  };

  const tabTitle = (
    <div className="d-inline-flex flex-wrap align-items-center">
      <div className="icon-wrapper">
        <Icon name="arrow-back" />
      </div>
      <span className="ml-2">{formatMessage(messages['sign.in.text'])}</span>
    </div>
  );

  return (
    <LayoutWrapper Layout={AuthnLayout}>
      <Helmet>
        <title>{formatMessage(messages['forgot.password.page.title'],
          { siteName: getConfig().SITE_NAME })}
        </title>
      </Helmet>
      <div>
        <div className="default-tabs-wrapper">
          <Tabs activeKey="" id="controlled-tab" onSelect={(k) => setKey(k)}>
            <Tab title={tabTitle} eventKey={LOGIN_PAGE} />
          </Tabs>
        </div>
        { key && (
          <Redirect to={updatePathWithQueryParams(key)} />
        )}
        <div id="main-content" className="main-content forgot-password-main-content">
          <Form id="forget-password-form" name="forget-password-form" className="mw-xs">
            <ForgotPasswordAlert email={bannerEmail} emailError={formErrors} status={status} />
            <Typography as="h2" variant="pBig" fontWeight="bold" className="mb-2">{formatMessage(messages['forgot.password.page.heading'])}</Typography>
            <Typography className="mb-4">{formatMessage(messages['forgot.password.page.instructions'])}</Typography>
            <TextField
              name="email"
              value={email}
              autoComplete="on"
              onChange={(e) => setEmail(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={Boolean(validationError)}
              errorText={validationError || ''}
              label={formatMessage(messages['forgot.password.page.email.field.label'])}
              helperText={formatMessage(messages['forgot.password.email.help.text'], { platformName })}
            />
            <div className="d-flex align-items-center button-wrapper--wrap button-wrapper-forgot">
              <LoadingButton
                id="submit-forget-password"
                name="submit-forget-password"
                type="submit"
                size="large"
                loading={submitState === 'pending'}
                onClick={handleSubmit}
                onMouseDown={(e) => e.preventDefault()}
              >
                {formatMessage(messages['forgot.password.page.submit.button'])}
              </LoadingButton>
              {(getConfig().LOGIN_ISSUE_SUPPORT_LINK) && (
                <Hyperlink
                  id="forgot-password"
                  name="forgot-password"
                  className="ml-3"
                  destination={getConfig().LOGIN_ISSUE_SUPPORT_LINK}
                  target="_blank"
                  showLaunchIcon={false}
                >
                  <Typography as="span" color="default" variant="pBig" fontWeight="bold">{formatMessage(messages['need.help.sign.in.text'])}</Typography>
                </Hyperlink>
              )}
            </div>
            <Typography variant="pSmall" color="secondary" className="mt-3">
              {formatMessage(messages['additional.help.text'], { platformName })}
              <Hyperlink isInline destination={`mailto:${getConfig().INFO_EMAIL}`} className="description-link">
                <Typography
                  variant="pSmall"
                  as="span"
                  color="default"
                  fontWeight="bold"
                >
                  {getConfig().INFO_EMAIL}
                </Typography>
              </Hyperlink>
            </Typography>
          </Form>
        </div>
      </div>
    </LayoutWrapper>
  );
};

ForgotPasswordPage.propTypes = {
  email: PropTypes.string,
  emailValidationError: PropTypes.string,
  forgotPassword: PropTypes.func.isRequired,
  setForgotPasswordFormData: PropTypes.func.isRequired,
  status: PropTypes.string,
  submitState: PropTypes.string,
};

ForgotPasswordPage.defaultProps = {
  email: '',
  emailValidationError: '',
  status: null,
  submitState: DEFAULT_STATE,
};

export default connect(
  forgotPasswordResultSelector,
  {
    forgotPassword,
    setForgotPasswordFormData,
  },
)(ForgotPasswordPage);
