import { getAuthenticatedHttpClient, getHttpClient } from '@edx/frontend-platform/auth';
import { getConfig } from '@edx/frontend-platform/config';
import formurlencoded from 'form-urlencoded';
import * as QueryString from 'query-string';

import { forgotPassword } from '../../forgot-password/data/service';

const requestConfig = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  isPublic: true,
};

export async function loginOnboardingCheckRequest(creds) {
  const url = new URL(`${getConfig().LMS_BASE_URL}/insider/onboarding-status/?email_or_username=${creds.email_or_username}`);

  const { data } = await getHttpClient()
    .post(url.href, formurlencoded(creds), requestConfig)
    .catch((e) => {
      throw (e);
    });

  if (data.onboarding) {
    localStorage.setItem('email_or_username', String(data.email));
    await forgotPassword(data.email);
  }

  return data;
}

// eslint-disable-next-line import/prefer-default-export
export async function loginRequest(creds) {
  const { data } = await getAuthenticatedHttpClient()
    .post(
        `${getConfig().LMS_BASE_URL}/api/user/v2/account/login_session/`,
        QueryString.stringify(creds),
        requestConfig,
    )
    .catch((e) => {
      throw (e);
    });

  return {
    redirectUrl: data.redirect_url || `${getConfig().LMS_BASE_URL}/dashboard`,
    success: data.success || false,
  };
}
